import Dexie from "dexie";

import {
  AddressDTO,
  AssetManufacturer,
  AssetType,
  CompanyDTO,
  CustomerDTO,
  FluidDTO,
  PlantDetailDTO,
  PlantDTO,
  PortTypeDTO,
  RouteDTO,
} from "api";
import {
  CustomerTestPackage,
  KnownAsset,
  LocalContact,
  LocalImageDTO,
  LocalSample,
} from "api_supplimental";
import tableDefinitions from "types/tableDefinitions.json";

export class Entities extends Dexie {
  Addresses: Dexie.Table<AddressDTO, number>;
  AssetManufacturers: Dexie.Table<AssetManufacturer, number>;
  AssetTypes: Dexie.Table<AssetType, number>;
  Assets: Dexie.Table<KnownAsset, string>;
  Companies: Dexie.Table<CompanyDTO, number>;
  Contacts: Dexie.Table<LocalContact, string>;
  Customers: Dexie.Table<CustomerDTO, number>;
  Fluids: Dexie.Table<FluidDTO, number>;
  Images: Dexie.Table<LocalImageDTO, string>;
  PlantDetails: Dexie.Table<PlantDetailDTO, number>;
  Plants: Dexie.Table<PlantDTO, number>;
  PortTypes: Dexie.Table<PortTypeDTO, number>;
  Routes: Dexie.Table<RouteDTO, number>;
  Samples: Dexie.Table<LocalSample, string>;
  TestPackages: Dexie.Table<CustomerTestPackage, number>;

  private static instance: Entities;

  constructor(userId: string) {
    const userIdNormalized = userId.toLowerCase();
    super(`Entities_${userIdNormalized || "UNKNOWN_USER"}`);
    if (!userIdNormalized) {
      throw new Error("No userId passed to Entities database constructor");
    }

    this.version(1).stores({
      Assets: "id",
      Plants: "id",
      Routes: "id",
      Fluids: "id",
    });
    this.version(2).stores({
      Assets: "id",
      Plants: "id",
      Routes: "id",
      Fluids: "id",
      Samples: "bottleId",
    });
    this.version(3).stores({
      Samples: "identifier",
    });
    this.version(4).stores({
      Samples: tableDefinitions.definitions.SampleDTO,
    });
    this.version(5).stores({
      Assets: "id,tagIdentifier",
    });
    this.version(6).stores({
      Customers: "id",
      AssetTypes: "id",
      PortTypes: "id",
      Plants: "id,customerId",
      AssetManufacturers: "id",
      TestPackages: "id,customerId",
      Contacts: "id,companyId",
      PlantDetails: "id",
      Addresses: "id",
    });
    this.version(7).upgrade(async () => {
      // Convert local contacts to use string IDs so we can use temporary UUID for creating offline contacts
      const existing = await this.Contacts.toArray();
      const withStringIds: LocalContact[] = existing.map((c) => {
        c.id = c.id.toString();
        return c;
      });
      await this.Contacts.clear();
      await this.Contacts.bulkPut(withStringIds);
    });
    this.version(8).stores({
      Assets: "id,tagIdentifier,routeId",
    });
    this.version(9).stores({
      Images: "guid,assetId,id",
    });
    this.version(10).stores({
      Companies: "id",
    });
    this.version(11).upgrade(async () => {
      // Require new samples and assets
      this.Assets.clear();
      this.Samples.clear();
    });
    this.version(12).upgrade(async () => {
      // Require new samples and assets
      this.Assets.clear();
      this.Samples.clear();
    });
    this.version(13).stores({
      Assets: "id,tagIdentifier,routeId,name,customerEquipmentId",
    });

    this.version(14).upgrade(async () => {
      this.Assets.clear();
      this.Samples.clear();
    });

    this.version(14).stores({
      Samples: "[labId+identifier],labId,identifier,assetId,userId",
    });

    this.Addresses = this.table("Addresses");
    this.AssetManufacturers = this.table("AssetManufacturers");
    this.AssetTypes = this.table("AssetTypes");
    this.Assets = this.table("Assets");
    this.Companies = this.table("Companies");
    this.Contacts = this.table("Contacts");
    this.Customers = this.table("Customers");
    this.Fluids = this.table("Fluids");
    this.Images = this.table("Images");
    this.PlantDetails = this.table("PlantDetails");
    this.Plants = this.table("Plants");
    this.PortTypes = this.table("PortTypes");
    this.Routes = this.table("Routes");
    this.Samples = this.table("Samples");
    this.TestPackages = this.table("TestPackages");
  }

  static getInstance(userId: string) {
    if (!Entities.instance) {
      Entities.instance = new Entities(userId);
    }
    return Entities.instance;
  }
}

type TEntityProps = Omit<Pick<Entities, keyof Entities>, keyof Dexie>;
export type TEntityTables = keyof TEntityProps;

export default Entities;
